import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { findIndex } from 'lodash';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneralHelper } from 'src/app/lib/helpers/general.helper';
import { INode } from 'src/app/lib/interfaces/interface';
import { LoggingService } from 'src/app/lib/services/logging.service';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { OptimizationService } from 'src/app/lib/services/optimization.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import {
  configOptimizationsAutoModeEnable,
  configOptimizationsDfsModeSet,
  configOptimizationsHopPenaltyModeSet,
  configOptimizationsMax24GhzChannelWidthSet,
  configOptimizationsMax5GhzChannelWidthSet,
  configOptimizationsMax6GhzChannelWidthSet,
  configOptimizationsPreCACSchedulerSet,
  configOptimizationsPrefer160MhzSet,
  configOptimizationsUnii4ModeSet,
  configOptimizationsZeroWaitDfsModeSet
} from 'src/app/store/configview/config.actions';
import { selectConfigAndState, selectPipeLocationOnChangeOptimistic } from 'src/app/store/customer/customer.selectors';
import {
  selectLocationOptimization,
  selectLocationQoE,
  selectLocationTopology,
  selectNodes
} from 'src/app/store/polling/polling.selector';

@Component({
  selector: 'optimization',
  templateUrl: './optimization.component.html',
  styleUrls: ['./optimization.component.scss']
})
export class OptimizationComponent implements OnInit, OnChanges, OnDestroy {
  qoeSubscription: any;
  topologySubscription: any;
  optimizationSubscription: any;
  nodesSubscription: any;
  expand: boolean = false;
  showFastInterface = false;
  fastInterface$ = this.optimizationService.getFastInterface$();
  autoOptimizeItems$ = this.store.pipe(selectPipeLocationOnChangeOptimistic).pipe(
    map((location) => [
      {
        value: true,
        marked: location.optimization.autoOptimization === true,
        translation: 'enabled',
        selected: location.optimizations.enable
      },
      {
        value: false,
        marked: location.optimization.autoOptimization === false,
        translation: 'disabled',
        selected: !location.optimizations.enable
      }
    ])
  );
  dfsModeItems$ = combineLatest([
    this.store.pipe(selectPipeLocationOnChangeOptimistic),
    of(this.plume.cloudVersionAbove1_103())
  ]).pipe(
    map(([location, isCorrectCloud]) => {
      const dfsModeItems = [
        { value: 'auto', translation: 'auto', selected: location.optimizations.dfsMode === 'auto' },
        {
          value: 'enable',
          translation: 'enabled',
          marked: location.dfsMode === 'enable',
          selected: location.optimizations.dfsMode === 'enable'
        },
        {
          value: 'disable',
          translation: 'disabled',
          marked: location.dfsMode === 'disable',
          selected: location.optimizations.dfsMode === 'disable'
        },
        {
          value: 'HomeNonDFSChannels',
          translation: 'backhaul',
          marked: location.dfsMode === 'HomeNonDFSChannels',
          selected: location.optimizations.dfsMode === 'HomeNonDFSChannels'
        },
        {
          value: 'usDfs',
          translation: 'usDfs',
          marked: location.dfsMode === 'usDfs',
          selected: location.optimizations.dfsMode === 'usDfs'
        }
      ];

      if (isCorrectCloud) {
        dfsModeItems.push({
          value: 'deviceAware',
          translation: 'deviceAware',
          marked: location.dfsMode === 'deviceAware',
          selected: location.optimizations.dfsMode === 'deviceAware'
        });
      }

      return dfsModeItems;
    })
  );
  zeroWaitDfsModeItems$ = this.store.pipe(selectPipeLocationOnChangeOptimistic).pipe(
    map((location) => [
      { value: 'auto', translation: 'auto', selected: location.optimizations.zeroWaitDfsMode === 'auto' },
      {
        value: 'enable',
        translation: 'enabled',
        marked: location.zeroWaitDfsMode === 'enable',
        selected: location.optimizations.zeroWaitDfsMode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: location.zeroWaitDfsMode === 'disable',
        selected: location.optimizations.zeroWaitDfsMode === 'disable'
      }
    ])
  );
  prefer160MhzItems$ = this.store.pipe(selectPipeLocationOnChangeOptimistic).pipe(
    map((location) => [
      { value: 'auto', translation: 'auto', selected: location.optimizations.prefer160MhzMode === 'auto' },
      {
        value: 'enable',
        translation: 'enabled',
        marked: location.prefer160MhzMode === 'enable',
        selected: location.optimizations.prefer160MhzMode === 'enable'
      },
      {
        value: 'disable',
        translation: 'disabled',
        marked: location.prefer160MhzMode === 'disable',
        selected: location.optimizations.prefer160MhzMode === 'disable'
      }
    ])
  );
  max24GhzChannelWidthItems$ = this.store.pipe(selectPipeLocationOnChangeOptimistic).pipe(
    map((location) => [
      {
        value: 'CHANNEL_BANDWIDTH_AUTO',
        translation: 'auto',
        selected: location.optimizations.maxBandwidth.maxBandwidth24g === 'CHANNEL_BANDWIDTH_AUTO'
      },
      {
        value: 'CHANNEL_BANDWIDTH_20',
        translation: '20',
        marked: location.maxBandwidth24G === 20,
        selected: location.optimizations.maxBandwidth.maxBandwidth24g === 'CHANNEL_BANDWIDTH_20'
      },
      {
        value: 'CHANNEL_BANDWIDTH_40',
        translation: '40',
        marked: location.maxBandwidth24G === 40,
        selected: location.optimizations.maxBandwidth.maxBandwidth24g === 'CHANNEL_BANDWIDTH_40'
      }
    ])
  );
  max5GhzChannelWidthItems$ = this.store.pipe(selectPipeLocationOnChangeOptimistic).pipe(
    map((location) => [
      {
        value: 'CHANNEL_BANDWIDTH_AUTO',
        translation: 'auto',
        selected: location.optimizations.maxBandwidth.maxBandwidth5g === 'CHANNEL_BANDWIDTH_AUTO'
      },
      {
        value: 'CHANNEL_BANDWIDTH_80',
        translation: '80',
        marked: location.maxBandwidth5G === 80,
        selected: location.optimizations.maxBandwidth.maxBandwidth5g === 'CHANNEL_BANDWIDTH_80'
      },
      {
        value: 'CHANNEL_BANDWIDTH_160',
        translation: '160',
        marked: location.maxBandwidth5G === 160,
        selected: location.optimizations.maxBandwidth.maxBandwidth5g === 'CHANNEL_BANDWIDTH_160'
      }
    ])
  );
  max6GhzChannelWidthItems$ = this.store.pipe(selectPipeLocationOnChangeOptimistic).pipe(
    map((location) => [
      {
        value: 'CHANNEL_BANDWIDTH_AUTO',
        translation: 'auto',
        selected: location.optimizations.maxBandwidth.maxBandwidth6g === 'CHANNEL_BANDWIDTH_AUTO'
      },
      {
        value: 'CHANNEL_BANDWIDTH_80',
        translation: '80',
        marked: location.maxBandwidth6G === 80,
        selected: location.optimizations.maxBandwidth.maxBandwidth6g === 'CHANNEL_BANDWIDTH_80'
      },
      {
        value: 'CHANNEL_BANDWIDTH_160',
        translation: '160',
        marked: location.maxBandwidth6G === 160,
        selected: location.optimizations.maxBandwidth.maxBandwidth6g === 'CHANNEL_BANDWIDTH_160'
      },
      {
        value: 'CHANNEL_BANDWIDTH_320',
        translation: '320',
        marked: location.maxBandwidth6G === 320,
        selected: location.optimizations.maxBandwidth.maxBandwidth6g === 'CHANNEL_BANDWIDTH_320'
      }
    ])
  );
  uniiItems$ = this.store.select(selectConfigAndState).pipe(
    map((configAndState) => [
      { value: 'AUTO', translation: 'auto', selected: configAndState.config.unii?.unii4Mode === 'AUTO' },
      {
        value: 'ENABLE',
        translation: 'enabled',
        marked: configAndState.state.unii?.unii4Enable,
        selected: configAndState.config.unii?.unii4Mode === 'ENABLE'
      },
      {
        value: 'DISABLE',
        translation: 'disabled',
        marked: !configAndState.state.unii?.unii4Enable,
        selected: configAndState.config.unii?.unii4Mode === 'DISABLE'
      }
    ])
  );
  hopPenaltyItems$ = this.store.pipe(selectPipeLocationOnChangeOptimistic).pipe(
    map((location) =>
      this.plume.cloudVersionAbove1_88()
        ? [
            {
              value: 'auto',
              translation: 'auto',
              selected: location.optimizations.hopPenalty === 'auto' || !location.optimizations.hopPenalty
            },
            {
              value: 'low',
              translation: 'low',
              marked: location.hopPenalty === 'low',
              selected: location.optimizations.hopPenalty === 'low'
            },
            {
              value: 'medium',
              translation: 'medium',
              marked: location.hopPenalty === 'medium',
              selected: location.optimizations.hopPenalty === 'medium'
            },
            {
              value: 'high',
              translation: 'high',
              marked: location.hopPenalty === 'high',
              selected: location.optimizations.hopPenalty === 'high'
            }
          ]
        : []
    )
  );
  dfsPreCacSchedulerItems$ = this.store.pipe(selectPipeLocationOnChangeOptimistic).pipe(
    map((location) => [
      {
        value: 'auto',
        translation: 'auto',
        marked: location.pcsMode === 'auto',
        selected: location.optimizations.preCACScheduler === 'auto'
      },
      {
        value: 'enable',
        translation: 'enable',
        marked: location.pcsMode === 'enable',
        selected: location.optimizations.preCACScheduler === 'enable'
      },
      {
        value: 'disable',
        translation: 'disable',
        marked: location.pcsMode === 'disable',
        selected: location.optimizations.preCACScheduler === 'disable'
      }
    ])
  );
  blacklistedLinks$ = this.store
    .pipe(selectPipeLocationOnChangeOptimistic)
    .pipe(map((location) => location.blacklistedLinks));
  details: any = null;
  nodes: any = [];
  nodesPool: any = [];
  nodeResponse: INode[] = null;
  qoeResponse: any = null;
  topologyResponse: any = null;
  timeoutInterval: any;
  timeout: number = 300000;
  debouncerTimeout: any;
  optimizeState: string = 'None';
  optimized: string = 'None';
  internetSubscription: any;
  optimizationChange: number = 0;
  helper: GeneralHelper = new GeneralHelper();
  enableOptimizationButton$ = this.optimizationService.enableOptimization$();

  @Input()
  open: boolean = false;

  @Output()
  toggle = new EventEmitter();

  @Output()
  filter = new EventEmitter();

  @Output()
  clearFilter = new EventEmitter<{ section: string }>();

  constructor(
    public plume: PlumeService,
    private mixpanel: MixpanelService,
    private toast: ToastService,
    private translate: TranslateService,
    private logging: LoggingService,
    private store: Store,
    private optimizationService: OptimizationService,
    private troubleshootingService: TroubleshootingService
  ) {}

  ngOnInit(): void {
    this.init();
    this.registerFilter();
  }

  ngOnChanges(changes: any): void {
    this.expand = changes.open.currentValue;
  }

  init(): void {
    if (!this.nodesPool || this.nodesPool.length === 0) {
      this.logging.warn('Nodes pool is empty, can not optimize');
    }

    this.optimizeState = 'loading';
    this.troubleshootingService.getOptimized().subscribe((response) => {
      this.optimizationState(
        response.optimization.state || 'techdash.infoArea.ready',
        response.optimization.stateChangedAt || Date.now(),
        true
      );
      this.addSubscriptionNotification();
    });

    this.qoeSubscription = this.store.select(selectLocationQoE).subscribe((response: any) => {
      if (response) {
        this.qoeResponse = JSON.parse(JSON.stringify(response));
        this.debounce(() => this.populate(), 500);
      }
    });

    this.topologySubscription = this.store.select(selectLocationTopology).subscribe((response: any) => {
      if (response) {
        this.topologyResponse = JSON.parse(JSON.stringify(response));
        this.debounce(() => this.populate(), 500);
      }
    });

    this.nodesSubscription = this.store.select(selectNodes).subscribe((nodes) => {
      this.nodeResponse = JSON.parse(JSON.stringify(nodes));
      this.debounce(() => this.populate(), 500);
    });
  }

  addSubscriptionNotification(): void {
    this.optimizationSubscription = this.store.select(selectLocationOptimization).subscribe((response: any) => {
      if (response) {
        this.optimizationState(response.state, response.stateChangedAt);
      }
    });
  }

  registerFilter(): void {
    this.clearFilter.emit({ section: 'optimization' });

    this.translate
      .get('configurations.optimization.autoOptimize')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'autoOptimize', translation: translated })
      );

    this.translate
      .get('configurations.optimization.dfsMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'dfsMode', translation: translated })
      );

    this.translate
      .get('configurations.optimization.zeroWaitDfsMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'zeroWaitDfsMode', translation: translated })
      );

    this.translate
      .get('configurations.optimization.prefer160hzMode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'prefer160hzMode', translation: translated })
      );

    this.translate
      .get('configurations.optimization.maxBandwidth.maxBandwidth24g')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'maxBandwidth.maxBandwidth24g', translation: translated })
      );

    this.translate
      .get('configurations.optimization.maxBandwidth.maxBandwidth5g')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'maxBandwidth.maxBandwidth5g', translation: translated })
      );

    this.translate
      .get('configurations.optimization.maxBandwidth.maxBandwidth6g')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'maxBandwidth.maxBandwidth6g', translation: translated })
      );

    this.translate
      .get('configurations.optimization.unii4Mode')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'unii4Mode', translation: translated })
      );

    if (this.plume.cloudVersionAbove1_88()) {
      this.translate
        .get('configurations.optimization.hopPenalty')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'optimization', property: 'hopPenalty', translation: translated })
        );

      this.translate
        .get('configurations.optimization.dfsPreCacScheduler')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'optimization', property: 'dfsPreCacScheduler', translation: translated })
        );
    }

    this.translate
      .get('configurations.optimization.blacklistedLinks')
      .subscribe((translated: string) =>
        this.filter.emit({ section: 'optimization', property: 'blacklistedLinks', translation: translated })
      );

    if (this.plume.isSomeAdminRole()) {
      this.translate
        .get('configurations.optimization.resetLinkFailures')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'optimization', property: 'resetLinkFailures', translation: translated })
        );

      this.translate
        .get('configurations.optimization.fastInterface')
        .subscribe((translated: string) =>
          this.filter.emit({ section: 'optimization', property: 'fastInterface', translation: translated })
        );
    }
  }

  debounce(fn: any, delay: number): void {
    clearTimeout(this.debouncerTimeout);
    this.debouncerTimeout = setTimeout(fn, delay);
  }

  populate(): void {
    const index = findIndex(this.nodes, { isRename: true });

    if (index < 0) {
      if (this.nodeResponse && this.qoeResponse && this.qoeResponse.nodes && this.topologyResponse) {
        this.nodesPool = this.nodeResponse;

        this.sort();
      } else {
        this.nodesPool = [];
        this.nodes = [];
      }
    }
  }

  sort(): void {
    const online = [];
    const offline = [];

    if (this.nodesPool.length) {
      this.nodesPool.forEach((node: any) => {
        if (node.connectionState === 'connected') {
          online.push(node);
        } else {
          offline.push(node);
        }
      });

      online.sort((node: any) => (node.isGateway ? -1 : 1));
      offline.sort((node: any) => (node.isGateway ? -1 : 1));

      this.nodes = [...online, ...offline];
    }
  }

  resetLinkFailures(): void {
    this.troubleshootingService.resetLinkFailures$().subscribe(
      () => {
        this.mixpanel.storeEvent('OPTIMIZER_CONFIG_RESET_LINK_FAILURES');
        this.toast.success(
          'configurations.optimization.toastResetLinkFailures',
          'configurations.optimization.toastTitle'
        );
      },
      (error) => {
        this.mixpanel.storeEvent('OPTIMIZER_CONFIG_RESET_LINK_ERROR');
        this.toast.error('configurations.optimization.toastResetLinkFailuresFailed', error.error.error.name, {
          params: {
            errorCode: error.error.error.statusCode
          }
        });
      }
    );
  }

  autoOptimize(event: boolean): void {
    this.store.dispatch(configOptimizationsAutoModeEnable({ value: event }));
  }

  action(command: string, action: string): void {
    switch (command) {
      case 'dfsMode':
        this.store.dispatch(configOptimizationsDfsModeSet({ value: action as any }));
        break;
      case 'zeroWaitDfsMode':
        this.store.dispatch(configOptimizationsZeroWaitDfsModeSet({ value: action as any }));
        break;
      case 'prefer160MhzMode':
        this.store.dispatch(configOptimizationsPrefer160MhzSet({ value: action as any }));
        break;
      case 'max24GhzChannelWidthMode':
        this.store.dispatch(configOptimizationsMax24GhzChannelWidthSet({ value: action as any }));
        break;
      case 'max5GhzChannelWidthMode':
        this.store.dispatch(configOptimizationsMax5GhzChannelWidthSet({ value: action as any }));
        break;
      case 'max6GhzChannelWidthMode':
        this.store.dispatch(configOptimizationsMax6GhzChannelWidthSet({ value: action as any }));
        break;
      case 'unii4Mode':
        this.store.dispatch(configOptimizationsUnii4ModeSet({ value: action as any }));
        break;
      case 'hopPenaltyMode':
        this.store.dispatch(configOptimizationsHopPenaltyModeSet({ value: action as any }));
        break;
      case 'dfsPreCacSchedulerItems':
        this.store.dispatch(configOptimizationsPreCACSchedulerSet({ value: action as any }));
    }
  }

  triggerOptimize(): void {
    if (
      this.validateOptimization() &&
      (this.optimizeState !== 'running' || this.getOptimizationLabel() === 'resetRetry')
    ) {
      this.troubleshootingService.triggerOptimization().subscribe(() => {
        this.mixpanel.storeEvent('MANUAL_OPTIMIZE', { SCREEN: 'CONFIGURATIONS' });
        this.optimizeState = 'running';
        this.optimized = 'initiated';
        this.toast.success(
          'toast.technician.successOptimizationStartedMessage',
          'toast.technician.successOptimizationStartedTitle'
        );
      });
    }
  }

  getOptimizationLabel(): string {
    const lastChange = (new Date().getTime() - this.optimizationChange) / 1000 / 60; // duration in minutes between now and last change
    if (this.optimized === 'initiated' && lastChange > 60) {
      return 'resetRetry';
    }
    return this.optimized;
  }

  optimizationState(state: any, changedAt: number, init: boolean = false): void {
    if (this.optimizationChange < changedAt) {
      this.optimizationChange = changedAt;
      this.optimized = state;

      switch (state) {
        case 'optimized':
          this.optimizeState = 'optimized';
          if (!init) {
            this.toast.success(
              'toast.technician.successOptimizationCompletedMessage',
              'toast.technician.successOptimizationCompletedTitle'
            );
          }
          break;
        case 'failed':
        case 'fail':
          this.optimizeState = 'fail';
          if (!init) {
            this.toast.warning(
              'toast.technician.successOptimizationFailedMessage',
              'toast.technician.successOptimizationFailedTitle'
            );
          }

          break;
        case 'initiated':
        case 'inProgress':
          this.optimizeState = 'running';
          break;
        default:
          this.optimizeState = state;
      }
    }
  }

  validateOptimization(): boolean {
    let counter = 0;

    for (const node of this.nodes) {
      if (node.connectionState === 'connected') {
        counter++;
      }
    }

    if (counter) {
      return true;
    } else {
      return false;
    }
  }

  toggleExpand(): void {
    this.toggle.emit(!this.expand);

    if (!this.expand) {
      this.mixpanel.storeEvent('CONFIGURATION_OPTIMIZATION_SCREEN');
    }
  }

  fastInterfaceDetails(): void {
    this.showFastInterface = true;
  }

  fastInterfaceReset(): void {
    this.optimizationService.deleteFastInterface$().subscribe(() => {
      this.toast.success(
        'configurations.optimization.toastResetFastInterface',
        'configurations.optimization.toastTitle'
      );
    });
  }

  ngOnDestroy(): void {
    if (this.qoeSubscription) {
      this.qoeSubscription.unsubscribe();
    }

    if (this.topologySubscription) {
      this.topologySubscription.unsubscribe();
    }

    if (this.optimizationSubscription) {
      this.optimizationSubscription.unsubscribe();
    }

    if (this.nodesSubscription) {
      this.nodesSubscription.unsubscribe();
    }

    if (this.internetSubscription) {
      this.internetSubscription.unsubscribe();
    }
  }
}
