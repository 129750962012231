import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';
import { MixpanelService } from 'src/app/lib/services/mixpanel.service';
import { PlumeService } from 'src/app/lib/services/plume.service';
import { ToastService } from 'src/app/lib/services/toast.service';
import { TroubleshootingService } from 'src/app/lib/services/troubleshooting.service';
import { updateLogpullHistory } from 'src/app/store/customer/customer.actions';

@Component({
  selector: 'logpullmodal',
  templateUrl: './logpullmodal.component.html',
  styleUrls: ['./logpullmodal.component.scss']
})
export class LogpullModalComponent {
  @Input()
  showLogPull: boolean = false;
  @Output()
  showLogPullChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  logpullComplete: boolean = false;
  messageFormControl: FormControl = new FormControl();
  logSubscription: Subscription;
  logpullResponse: any;

  constructor(
    public plume: PlumeService,
    private toast: ToastService,
    private mixpanel: MixpanelService,
    private clipboard: ClipboardService,
    private troubleshooting: TroubleshootingService,
    private store: Store
  ) {}

  copied(url: string): void {
    this.mixpanel.storeEvent('LOGPULL_HISTORY_COPY');
    this.clipboard.copyFromContent(url);
    this.toast.success(
      'customerinfo.logpullHistory.copyUrlToastMessage',
      'customerinfo.logpullHistory.copyUrlToastTitle'
    );
  }

  performLogpull(): void {
    this.logSubscription = this.troubleshooting.pulllog.subscribe((response: any) => {
      this.logpullComplete = true;
      this.logpullResponse = response;
      this.logSubscription.unsubscribe();
      this.store.dispatch(updateLogpullHistory());
    });

    const message = this.messageFormControl.value ? this.messageFormControl.value : 'PlumeCentral - Manual';
    this.mixpanel.storeEvent('LOGPULL_MANUAL_REQUEST', { MESSAGE: message });
    this.troubleshooting.initPullLog(message);
    this.messageFormControl.setValue('');
  }

  closeLogpull(): void {
    this.showLogPullChange.emit(false);
    this.messageFormControl.setValue('');
    this.logpullComplete = false;
  }
}
