import { double } from 'aws-sdk/clients/lightsail';
import { DataSetGroup } from '../graph/graph.interface';

export type autoModes = 'AUTO' | 'ENABLE' | 'DISABLE';

export type DeepReadonly<T> = T extends (infer R)[]
  ? DeepReadonlyArray<R>
  : /* tslint:disable */
  T extends Function
  ? T
  : T extends object
  ? DeepReadonlyObject<T>
  : T;

interface DeepReadonlyArray<T> extends ReadonlyArray<DeepReadonly<T>> {}

type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface IThreadNetwork {
  enable: boolean;
  threadInterface: string;
  networkInterface: string;
  dataset: string;
  networkName: string;
  panId: number;
  extPanId: string;
  networkKey: string;
  meshLocalPrefix: string;
  channel: number;
  channelMask: number;
  commissioningPsk: string;
  reportingInterval: number;
}

export interface IThreadDevice {}

export interface IThreadTopology {
  edges: any[];
  vertices: any[];
}

export interface IThreadNodeState {
  nodeId: string;
  dataset: string;
  eui64: {
    value: string;
  };
  extAddress: {
    value: string;
  };
}

export interface IConfigAndStateWan {
  enable: boolean;
  pppoe: {
    username: string;
    password: string;
  };
  vlan: {
    vlanId: string;
  };
  staticIp: {
    ip: string;
    gateway: string;
    subnet: string;
    primaryDns: string;
    secondaryDns: { value: string };
  };
  priority: number;
  status: { value: string };
  osPersist: boolean;
}

export interface IConfigAndState {
  config: {
    locationId?: string;
    lowPowerMode?: {
      optIn: boolean;
    };
    nodeLogging?: {
      forceDestination?: logModes;
    };
    networks?: {
      secondaryNetworks: {
        networkId: string;
        ssid: string;
        enable: boolean;
        wpaMode: { value: string };
        ssidBroadcast: { value: boolean };
        dppConfigurator: null;
        fronthaulConfig: { accessZone: string };
        config: string;
      }[];
    };
    lte?: {
      nodeId: { value: string };
      enable: { value: boolean };
      apn?: { value: string };
      persist: autoModes;
      secondaryNetworks?: { networkId: string; enable: boolean; wan: boolean }[];
    };

    samKnows?: { mode: autoModes };
    sipAlg?: { mode: autoModes };
    puncturing?: { mode: autoModes };
    appTime?: {
      appQoe: autoModes;
    };
    powerManagement?: { mode: 'AUTO' | 'ENABLE' | 'DISABLE' };
    thread?: IThreadNetwork;
    networkConfiguration?: {
      natLoopback?: { mode: 'AUTO' | 'ENABLE' | 'DISABLE' };
    };
    unii?: {
      unii4Mode: 'AUTO' | 'ENABLE' | 'DISABLE';
    };
  };
  state: {
    nodeState?: {
      wan: IConfigAndStateWan[];
      nodeId: string;
      isGateway: boolean;
      isMasterGateway: boolean;
      isRouter: boolean;
      capabilities: { lte: boolean };
    }[];
    locationId?: string;
    capabilities?: Partial<{
      appPrioritization?: boolean;
      flex?: boolean;
      lte?: boolean;
      powerManagement?: boolean;
      samKnows?: boolean;
      sipAlg?: boolean;
      appQoe?: boolean;
      fronthauls?: boolean;
      captivePortal?: boolean;
      captivePortalFronthaul?: boolean;
      captivePortalV2?: boolean;
      bandwidthThrottling?: boolean;
      thread?: boolean;
      puncturing?: boolean;
      cellularStatistics?: boolean;
      blockActiveAppFlows?: boolean;
    }>;
    lte?: {
      enable: boolean;
      nodeId: { value: string };
      active: boolean;
      force: boolean;
      iccid: { value: string };
      imei: { value: string };
      linkState: 'LINK_READY' | 'NO_COVERAGE' | 'LINK_UP';
      publicIp?: string;
      apn?: { value: string };
      persist: boolean;
    };
    puncturing?: { enable: boolean };
    samKnows?: { enable: boolean };
    sipAlg?: { enable: boolean };
    appTime?: {
      appQoe: boolean;
    };
    powerManagement?: IPowerState;
    mapTe?: IMapConfigState;
    thread?: {
      threadDevices: IThreadDevice[];
      threadNodeStates: IThreadNodeState[];
    };
    unii?: {
      unii4Enable: boolean;
    };
  };
  loadedTime?: number;
}

export type logModes =
  | 'NODE_LOGGING_DESTINATION_DEFAULT'
  | 'NODE_LOGGING_DESTINATION_FLASH'
  | 'NODE_LOGGING_DESTINATION_FLASH_RAMOOPS'
  | 'NODE_LOGGING_DESTINATION_RAMOOPS'
  | 'NODE_LOGGING_DESTINATION_OFF';

export const LogDestination = {
  NODE_LOGGING_DESTINATION_DEFAULT: 'NODE_LOGGING_DESTINATION_DEFAULT',
  NODE_LOGGING_DESTINATION_FLASH: 'NODE_LOGGING_DESTINATION_FLASH',
  NODE_LOGGING_DESTINATION_FLASH_RAMOOPS: 'NODE_LOGGING_DESTINATION_FLASH_RAMOOPS',
  NODE_LOGGING_DESTINATION_RAMOOPS: 'NODE_LOGGING_DESTINATION_RAMOOPS',
  NODE_LOGGING_DESTINATION_OFF: 'NODE_LOGGING_DESTINATION_OFF'
};

export const LPMState = {
  STATUS_DEACTIVATE: 'DEACTIVATE',
  STATUS_ACTIVATE: 'ACTIVATE'
};

export const LPMStatus = {
  STATUS_UNKNOWN: 'PM_STATUS_UNKNOWN',
  STATUS_ACTIVE: 'PM_STATUS_ACTIVE',
  STATUS_ACTIVATING: 'PM_STATUS_ACTIVATING',
  STATUS_INACTIVE: 'PM_STATUS_INACTIVE',
  STATUS_DEACTIVATING: 'PM_STATUS_DEACTIVATING'
};

export const LPMConfig = {
  CONFIG_ENABLE: 'ENABLE',
  CONFIG_AUTO: 'AUTO',
  CONFIG_DISABLE: 'DISABLE'
};

export interface IPowerState {
  enable: boolean;
  status:
    | 'PM_STATUS_UNKNOWN'
    | 'PM_STATUS_ACTIVE'
    | 'PM_STATUS_INACTIVE'
    | 'PM_STATUS_ACTIVATING'
    | 'PM_STATUS_DEACTIVATING';
  forcePowerManagementExpiresAt: null | { value: number };
  transitionTrigger:
    | 'PM_TRIGGER_UNKNOWN'
    | 'PM_TRIGGER_SCHEDULED'
    | 'PM_TRIGGER_CUSTOMER_FORCED'
    | 'PM_TRIGGER_SUPPORT_FORCED'
    | 'PM_TRIGGER_BANDWIDTH_THRESHOLD_EXCEEDED'
    | 'PM_TRIGGER_CONFIG_CHANGED'
    | 'PM_TRIGGER_NETWORK_ACTIVITY'
    | 'PM_TRIGGER_INTERFERENCE'
    | 'PM_TRIGGER_TOPOLOGY_FAILED'
    | 'PM_TRIGGER_EXPIRED'
    | 'PM_TRIGGER_TOPOLOGY_DEVIATED'
    | 'PM_TRIGGER_LINK_DISCOVERY'
    | 'PM_TRIGGER_LOCATION_OFFLINE';
  error: 'PM_NO_ERROR' | 'PM_ERROR_UNKNOWN';
}

export interface ICustomer {
  loadedTime?: number;
  id: string;
  accountId: string;
  anonymous: boolean;
  autoProvisioned: boolean;
  name: string;
  locked: boolean;
  partnerId: string;
  acceptLanguage: string;
  preferredLanguage: string;
  source: string;
  createdAt: string;
  _version: string;
  email: string;
  emailVerified: boolean;
  termsAndPrivacy: {
    termsDocumentId: number;
    termsAcceptedAt: string;
    privacyDocumentId: number;
    privacyAcceptedAt: string;
  };
  loginFailure: number[];
  firstKnownLoginTimestamp: string;
  partnerName: string;
  provisioningSsoAuditTrail?: boolean;
}

export interface IModeString {
  mode: string;
}

export interface ILocationGeoIp {
  country: string;
  city: string;
  latitude: number;
  longitude: number;
  timezone: string;
  ISP: string;
  updatedAt: string;
  wifiIsoCode: string;
  zip: string;
}

export interface ILocationSteering {
  auto: boolean;
  mode: string;
  updatedAt: string;
  enable?: boolean;
}

export interface IIspSpeedTestConfiguration {
  enable: boolean;
  enableAllNodes: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ILocationIpv6 {
  mode: string;
  addressingConfig: string;
  enable: boolean;
  addressingRealized: string;
  updatedAt: string;
  wan: {};
  prefix: string;
  dns: {
    primary: string;
    secondary: string;
    enabled: boolean;
  };
}

export interface ILocationAuthorizations {
  numPodsAuthorized: number;
  numNodesAuthorized: { model: string; count: number }[];
  updatedAt: string;
}

export interface ILocationDppConfiguration {
  mode: 'auto' | 'enable' | 'disable';
  enrollments: [];
  modeRealized: 'enable' | 'disable';
}

export interface ILocationBackhaul {
  mode: string;
  dynamicBeacon: string;
  wds: string;
  wdsRealized: string;
  wpaMode: string;
  wpaModeRealized: string;
  createdAt: string;
  updatedAt: string;
  encrypted: boolean;
  encryptedAt: string;
  enable: boolean;
  dynamicBeaconEnable: boolean;
  wdsEnable: boolean;
  hitlessTopology: string;
  hitlessTopologyEnable: boolean;
}

export interface ILocationSecurityConfiguration {
  dosProtection: {
    action: 'auto' | 'observe' | 'block';
    mode: 'auto' | 'enable' | 'disable';
  };
  preferredIntelligence: 'auto' | 'webpulse' | 'brightcloud' | 'gatekeeper';
  ipThreatIntelligence: 'auto' | 'webpulse' | 'gatekeeper';
  wcHealthTelemetry: 'auto' | 'enable' | 'disable';
  wcHeroTelemetry: 'auto' | 'enable' | 'disable';
  ipThreatProtect: 'auto' | 'enable' | 'disable';
  dpiContentFiltering: 'auto' | 'enable' | 'disable';
  ipThreatProvider: 'auto' | 'plume' | 'norton';
  inlineDpi: 'auto' | 'enable' | 'disable';
}

export interface ILocation {
  id: string;
  ipv6: ILocationIpv6;
  networkMode: 'router' | 'bridge' | 'auto';
  name: string;
  accountId: string;
  partnerId: string;
  groupIds: string[];
  serviceId: string;
  profile: 'auto' | 'smallbusiness' | 'property';
  mDNSUniqueIdentifier: string;
  freezeTemplates: {
    id: string;
    macs: string[];
    createdAt?: string;
    endTime?: string;
    freeze?: boolean;
    priority?: number;
    endDate?: string;
    schedules?: any[];
  }[];
  serviceLevel: { auto: boolean; status: string };
  appTime: boolean | { enable: boolean; updatedAt: string };
  createdAt: string;
  updatedAt: string;
  stepFunctionTimers: [];
  groupOfUnassignedDevicesFreezeTemplates: any[];
  groupOfUnassignedDevicesFreezeSchedules: any[];
  isUtilizingSharedLocationFreezeSchedules: boolean;
  isUtilizingFocuses?: boolean;
  customerId: string;
  ispSpeedTestConfiguration: IIspSpeedTestConfiguration;
  bandSteering: ILocationSteering;
  clientSteering: ILocationSteering;
  controlMode: string | IModeString;
  monitorMode: { enable: boolean; updatedAt: string };
  backhaul: ILocationBackhaul;
  wifiMotion: { auto: boolean; createdAt: string; updatedAt: string };
  geoIp?: ILocationGeoIp;
  optimizations: {
    auto: boolean;
    enable: boolean;
    dfsMode: string;
    prefer160MhzMode: string;
    maxBandwidth: {
      maxBandwidth24g?: string;
      maxBandwidth5g?: string;
      maxBandwidth6g?: string;
    };
    hopPenalty: string;
    preCACScheduler: string;
    updatedAt: string;
    zeroWaitDfsMode: string;
    prefer160MhzModeRealized: string;
  };
  authorizations: ILocationAuthorizations;
  locale: { region: string };
  dppConfiguration: ILocationDppConfiguration;
  haahsConfiguration: {
    mode: string;
    subscription: { status: string; createdAt: string };
  };
  onboardedAt: string;
  flowCollectionConfig: {
    ipIotDeviceFlow: string;
    ipScreenDeviceFlow: string;
    lanIotDeviceFlow: string;
    interfaceStats: string;
    updatedAt: string;
  };
  customSharedSchedules: {
    id: string;
    name: string;
    type: string;
    schedules: { startTime: string; endTime: string | null; daysOfWeek: number[] }[];
    createdAt: string;
    updatedAt: string;
  }[];
  featureConfigIds: { id: string; priority: number }[];
  partnerName: string;
  optimization: {
    id: string;
    state: string;
    priority: number;
    triggers: string[];
    deviatedNodes: [];
    stateChangedAt: number;
    createdAt: string;
    autoOptimization: boolean;
    uuid: string;
  };
  capabilities: {
    networkConfiguration: {
      networkMode: boolean;
      upnp: boolean;
      igmpSnooping: boolean;
      igmpProxy: boolean;
      mldProxy: boolean;
      multicastToUnicast: boolean;
      dnsServers: boolean;
      dhcpReservations: boolean;
      portForward: boolean;
      dhcp: boolean;
      fastTransition: boolean;
      minWifiMode24: boolean;
      persistConfigurationOnGateway?: boolean;
    };
    flex: boolean;
    sipAlg: boolean;
    powerManagement: boolean;
    ispSpeedTest: boolean;
    isRadioDisableSupported: boolean;
    nodeIspSpeedTest: boolean;
    bandSteering: boolean;
    backhaul: { dynamicBeacon: boolean; wds: boolean; hitlessTopology: boolean };
    ipv6: { mode: boolean; addressingTypes: string[] };
    clientSteering: boolean;
    deviceFreeze: boolean;
    multiPasswordSSID: boolean;
    wifiMotion: boolean;
    kvConfig: boolean;
    onboardNetwork: boolean;
    security: { dnsCategoryBlocking: boolean; anomaly: boolean; ipThreatProviders: string[] };
    wps: boolean;
    controlMode: { full: boolean; monitor: boolean; reduced: boolean; battery: boolean };
    appMetadata: boolean;
    macPrioritization: boolean;
    dpp: boolean;
    wifi: { wpaModes: string[] };
    haahs: boolean;
    zeroWaitDfs: boolean;
  };
  fastTransition: boolean;
  groupRekey: boolean;
  minWifiMode24: string;
  networkModeRealized: string;
  upstreamDnsServers: { primaryDns: string };
  regulatoryCompliance: { region: string };
  firmwareUpgrade: {
    pendingVersionMatrixId?: string;
    activeVersionMatrixId: string;
    batchFirmwareUpgradeId: string;
    preventDowngrade: boolean;
    updatedAt: string;
  };
  targetTopology: {
    id: string;
    ipv6: string[];
    wifiConfig: { freqBand: string; channel: number; parentId: string; devices: string[]; vaps: [] }[];
    ethernetDevices: [];
    mocaDevices: [];
  }[];
  speedTest: {
    id: string;
    startedAt: string;
    endedAt: string;
    gateway: boolean;
    status: string;
    trigger: string;
    serverName: string;
    isp: string;
    download: number;
    upload: number;
    rtt: number;
    serverId: number;
    downloadDuration: number;
    uploadDuration: number;
    duration: number;
  };
  igmpSnooping: boolean;
  dfsMode: string;
  multicast: { igmpSnooping: boolean; igmpProxy: string; mldProxy: string; multicastToUnicast: boolean };
  zeroWaitDfsMode: string;
  ethernetLan: { enable: boolean };
  prefer160MhzMode: string;
  maxBandwidth5G: number;
  maxBandwidth6G: number;
  maxBandwidth24G: number;
  upnp: boolean;
  persistConfigurationOnGateway: boolean;
  dpp: boolean;
  vlanServices: { name: string; enable: boolean }[];
  hopPenalty: 'low' | 'medium' | 'high';
  pcsMode: 'auto' | 'enable' | 'disable';
  security: {
    ipThreatProvider: string;
    dpiContentFiltering: boolean;
    ipThreatEnable: boolean;
    contentFilters: string[];
    inlineDpi: boolean;
    intelligence: 'auto' | 'webpulse' | 'brightcloud' | 'gatekeeper';
    ipThreatIntelligence: string;
    wcHealthTelemetry: boolean;
    wcHeroTelemetry: boolean;
    dosProtection: {
      enable: boolean;
      action: 'observe' | 'block';
    };
  };

  haahs: { vapEnabled: boolean; enable: boolean };
  flowCollection: {
    lanStats: boolean;
    interfaceStats: boolean;
    iotDeviceIpStats: boolean;
    screenDeviceIpStats: boolean;
  };
  flex: boolean;
  uprise: boolean;
  busy: boolean;
  blacklistedLinks: [];
  ssid: string;
  securityConfiguration?: ILocationSecurityConfiguration;
  version?: string;
  loadedTime?: number;
  errorRecoverDetector?: number; // change if recovered from error to populate everywhere as new change
  upriseConfig?: {
    iotConfig?: {
      propertyId: string;
      iotNetwork: boolean;
      isOriginalIOTNetwork: boolean;
    };
  };
}

export type SlideTogglerItem<T> = {
  value: T;
  translation: string;
  selected: boolean;
  marked?: boolean;
  unselectable?: boolean;
  icon?: string;
  iconColor?: string;
};

export type SlideTogglerItems<T> = SlideTogglerItem<T>[];

export interface ICapabilities {
  networkConfiguration: {
    networkMode: { disabled: boolean };
    upnp: { disabled: boolean; reason?: string };
    igmpSnooping: { disabled: boolean; reason?: string };
    igmpProxy: { disabled: boolean; reason?: string };
    mldProxy: { disabled: boolean; reason?: string };
    multicastToUnicast: { disabled: boolean; reason?: string };
    dnsServers: { disabled: boolean; reason?: string };
    dhcpReservations: { disabled: boolean; reason?: string };
    portForward: { disabled: boolean; reason?: string };
    dhcp: { disabled: boolean; reason?: string };
    persistConfigurationOnGateway: { disabled: boolean; reason?: string };
  };
  deviceFreeze: { disabled: boolean; reason?: string };
  ispSpeedTest: { disabled: boolean; reason?: string };
  nodeIspSpeedTest: { disabled: boolean; reason?: string };
  multiPasswordSSID: { disabled: boolean; reason?: string };
  security: { dnsCategoryBlocking: { disabled: boolean; reason?: string } };
  wifiMotion: { disabled: boolean; reason?: string };
  appTime: { disabled: boolean; reason?: string };
  wpa3: { wpaModes: string[]; recommendation: string };
  dpp: boolean;
  haahs: boolean;
  lteBackup: boolean;
  mdns: boolean;
  multiPassword: boolean;
  powerManagement: boolean;
}

export interface ITopologyPod {
  connectionState: 'connected' | 'disconnected' | 'unavailable';
  health: { status: string };
  alerts: [];
  type: 'pod';
  label: string;
  metadata: {
    networkMode: string;
    backhaulType: string;
    connectedDeviceCount: number;
    firmwareVersion: string;
    platformVersion: string;
    model: string;
    serialNumber: string;
    mac: string;
    radio24?: { channel: number; vaps: string[] };
    radio50L?: { channel: number; vaps: string[] };
    radio50U?: { channel: number; vaps: string[] };
  };
  id: string;
}

export interface ITopologyDevice {
  connectionState: 'connected' | 'disconnected';
  health: { status: string };
  alerts: [];
  isPartnerComponent: boolean;
  id: string;
  type: 'device';
  label: string;
  metadata: {
    icon: string;
    iconV2: string;
    mac: string;
    vapType: string;
    networkId: string;
  };
}

export interface ITopology {
  vertices: (ITopologyPod | ITopologyDevice)[];
  edges: {
    source: string;
    target: string;
    direction: string;
    medium: string;
    metadata: { parentVapType: string; freqBand: string; channel: number; channelWidth: number };
  }[];
}

export interface IReportBroadbandEfficiencyAlert {
  summary: {
    twentyFourHours: {
      broadbandDownloadSpeed: { average: number; standardDeviation: number };
      wifiDownloadSpeed: { average: number; standardDeviation: number };
    };
    seventyTwoHours: {
      broadbandDownloadSpeed: { average: number; standardDeviation: number };
      wifiDownloadSpeed: { average: number; standardDeviation: number };
    };
  };
  nodes: {
    nodeId: string;
    broadbandDownloadSpeed?: { average: number; standardDeviation: number };
    wifiDownloadSpeed?: { average: number; standardDeviation: number };
    broadbandEfficiency?: number;
  }[];
}

export interface IPrimarySecondaryNetworks {
  wpa3ssid?: string;
  wpa3enabled?: boolean;
  wpa3encryptionKey?: string;
  wpa2ssid?: string;
  wpa2enabled?: boolean;
}

export interface ICustomerSupportConfig {
  customerFeatureEnablement?: {
    allowSecondaryNetworkFronthaulWpaModeOpenEnabled?: boolean;
    hideSpeedTestUiEnabled?: boolean;
    mobileCustomDeeplinkEnabled?: boolean;
    displayCustomLogoEnabled?: boolean;
    hideIspNameEnabled?: boolean;
    chatSupportEnabled?: boolean;
    partnerAccountEnabled?: boolean;
    manageAccountEnabled?: boolean;
    autorunSpeedtestToggleEnabled?: boolean;
    manageYourDataEnabled?: boolean;
    removeGatewayEnabled?: boolean;
    termsEnabled?: boolean;
    privacyEnabled?: boolean;
    partnerBuyPodUrlEnabled?: boolean;
    buyPodEnabled?: boolean;
    multilocationEnabled?: boolean;
    callUsEnabled?: boolean;
    contactUsEnabled?: boolean;
    removePodEnabled?: boolean;
    adaptOnlyEnabled?: boolean;
    addPodEnabled?: boolean;
    faqEnabled?: boolean;
  };
  customerConfigurations?: {
    captivePortalDefaultLoginOptions?: string;
    iosStoreApplicationUrl?: string;
    androidStoreApplicationUrl?: string;
    mobileCustomDeeplinkUrl?: string;
    chatSupportUrl?: string;
    partnerAccountUrl?: string;
    plumeAccountUrl?: string;
    manageYourDataUrl?: string;
    callUsDisplayPhoneNumber?: string;
    callUsPhoneNumber?: string;
    contactUsEmail?: string;
    partnerBuyPodUrl?: string;
    plumeBuyPodUrl?: string;
    faqUrl?: string;
    termsUrl?: string;
    mobileAppDefaultDeepLink?: string;
    privacyUrl?: string;
  };
  updatedAt?: string;
  customerBrandingFiles?: {
    webBrandingFiles?: {
      logoResource?: string;
    };
  };
}

export interface INode {
  id: string;
  connectionState: 'connected';
  nickname: string;
  name: string;
  model: string;
  mac: string;
  ip: string;
  dhcpLease?: IDHCPLeaseRecord;
  dhcpV6Lease?: IDHCPV6Record;
  backhaulType: 'ethernet' | 'wifi';
  defaultName: string;
  radioMac24: string;
  radioMac50L: string;
  radioMac50U: string;
  ethernet1Mac: string;
  bluetoothMac: string;
  serialNumber: string;
  ipv6: string[];
  firmwareVersion: string;
  platformVersion: string;
  connectedDeviceCount: number;
  connectionStateChangeAt: string;
  networkMode: 'bridge' | 'router';
  leafToRoot: {
    id: string;
    nickname: string;
  }[];
  linkStates?: {
    duplex: string;
    hasEthClient: boolean;
    ifName: string;
    isUplink: boolean;
    linkSpeed: number;
  }[];
  packId: string;
  subscriptionRequired: boolean;
  claimedAt: string;
  residentialGateway: boolean;
  speedTest: {
    id: string;
    startedAt: string;
    endedAt: string;
    gateway: boolean;
    status: 'succeeded';
    trigger: 'scheduled';
    serverName: string;
    isp: string;
    download: number;
    upload: number;
    rtt: number;
    downloadDuration: number;
    uploadDuration: number;
    duration: number;
  };
  manufacturerSerialNumber: string;
  vendor: {
    name: string;
    partNumber: string;
    manufacturer: string;
    factory: string;
    mfgDate: string;
  };
  openSyncVersion?: string;
  vendorName?: string;
  certificates: {
    clientAfter: string;
    clientBefore: string;
    rootAfter: string;
    rootBefore: string;
  };
  backhaulChannel?: number;
  ethernetLan: {
    [key: string]: {
      mode: 'auto';
    };
  };
  bootAt: string;
  shipDate: string;
  partNumber: string;
  publicIp: string;
  alerts: [];
  '2gChannel': number;
  '5gChannel': number;
  '5guChannel': number;
  '5glChannel': number;
  wpaMode24g?: string;
  wpaMode5gu?: string;
  wpaMode5gl?: string;
  wpaMode6g?: string;
  health: {
    status: 'excellent' | 'poor';
    score: number;
    details: null;
  };
}

export interface IDevice {
  mac: string;
  keyId: number;
  hostName: string;
  medium: string;
  connectionState: '' | 'connected' | 'disconnected' | 'unavailable';
  connectionStateChangeAt: string;
  vapType: string;
  networkId: string;
  favorite: boolean;
  appTime: boolean;
  dhcpLease?: IDHCPLeaseRecord;
  dhcpV6Lease?: IDHCPV6Record;

  bandSteering: {
    _version: string;
    enable: boolean;
    auto: boolean;
  };
  clientSteering: {
    _version: string;
    enable: boolean;
    auto: boolean;
    configSource: string;
    updatedAt?: string;
    disableReasons: string[];
  };
  qos: {
    prioritization: {
      mode: string;
      realizedState: string;
    };
  };
  ip: string;
  ipv6: string[];
  channel: number;
  freqBand: string;
  steering: {};
  name: string;
  icon: string;
  iconV2: string;
  iconV3: string;
  category: string;
  brand: string;
  osName: string;
  health: {
    score: number;
    status: string;
    details?: any;
  };
  leafToRoot: {
    id: string;
    nickname: string;
    parentId: string;
    radio: string;
    channel?: number;
    medium: string;
  }[];
  alerts: [];
  freeze: {
    timeTemplates: [];
    suspended: {
      id: string;
      name: string;
      enable: boolean;
    };
    autoExpire: {
      id: string;
      enable: boolean;
      expiresAt?: string;
    };
    schedules: [];
    forever: {
      id: string;
      name: string;
      enable: boolean;
    };
    frozen: boolean;
  };
  locallyAdministeredShifty: boolean;
  locallyAdministeredShiftyExpired: boolean;
  locallyAdministeredMac: boolean;
  accessZone: {
    id: number;
    type: string;
    description: string;
    createdAt: Date;
    _version: string;
  };
  firstConnectedAt: Date;
  capabilities: {
    radio24: boolean;
    radio50: boolean;
    radio60: boolean;
  };
  features: {};
  isPartnerComponent: boolean;
  kind: {
    id: string;
    type: {
      id: string;
      category: string;
      brand: string;
      name: string;
      modelNumber: string;
      icon: string;
      iconV2: string;
      iconV3: string;
      osName: string;
      osVersion: string;
      source: string;
      confidence: number;
      stationary: boolean;
      model: string;
    };
    category: string;
    brand: string;
    name: string;
    modelNumber: string;
    icon: string;
    iconV2: string;
    iconV3: string;
    osName: string;
    osVersion: string;
    source: string;
    confidence: number;
    stationary: boolean;
    typeIdentified: boolean;
    ohpCapable: boolean;
    model: string;
  };
  nickname: string;
  plumeTypeIdentified: boolean;
  customerTypeIdentified: boolean;
  ohp: {
    capable: boolean;
  };
  accessZoneType: string;
  quarantine: {
    enable: boolean;
    anomalyBlacklist: [];
    anomalyWhitelist: [];
  };
  groupOfUnassignedDevices: boolean;
  networkAccess: {
    mode: string;
  };
  model: string;
  personId: string;
  networkHistory: {
    networkId: string;
    connectionStateChangedAt: Date;
  }[];
  hiddenAt?: Date;
}

export interface IDeviceFeatureBandwidthData {
  bandwidth: {
    daily: {
      download: number;
      upload: number;
      downloadUnits: string;
      uploadUnits: string;
      rank: number;
      percentage: number;
      totalConsumption: {
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
      };
    };
    weekly: {
      download: number;
      upload: number;
      downloadUnits: string;
      uploadUnits: string;
      rank: number;
      percentage: number;
      totalConsumption: {
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
      };
    };
    monthly: {
      download: number;
      upload: number;
      downloadUnits: string;
      uploadUnits: string;
      rank: number;
      percentage: number;
      totalConsumption: {
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
      };
    };
  };
}

export interface IDeviceFeatureChartsData {
  dailyChart: Array<{
    timestamp: number;
    download: number;
    upload: number;
    total: number;
  }>;
  weeklyChart: Array<{
    timestamp: number;
    download: number;
    total: number;
    upload: number;
  }>;
  monthlyChart: Array<{
    timestamp: number;
    download: number;
    upload: number;
    total: number;
  }>;
}

export interface IParsedHostName {
  deployment: 'noc' | 'central' | 'noc-dev' | 'central-dev' | 'noc-stg' | 'central-stg';
  cloud: string;
  partner?: string;
  group?: string;
  lang?: string;
}

export interface IQoeMetrics {
  statsDateRange: { start: number; end: number };
  nodes: ({
    nodeId: string;
  } & {
    [metricName: string]: {
      timestamp: number;
      value: number;
    }[];
  })[];
  devices: ({
    mac: string;
  } & {
    [metricName: string]: {
      timestamp: number;
      value: number;
    }[];
  })[];
}

export interface Header {
  key: string;
  value: string;
  desc: boolean;
}

export interface HeadersConfig {
  sort: string;
  headers: Header[];
}

export interface I5gQoeMetrics {
  location_id: string;
  node_id: string;
  pcid: string;
  cell_mode: string;
  freq_band: double;
  actual_throughput: double;
  qoe_score: double;
  signal_bars: double;
  speedtest_latency: double;
  estimated_throughput: double;
  estimated_throughput_neigbours: {};
  timestamps_used: string[];
  end_ts: string;
}

export interface INeighborReportCns {
  nodeId: string;
  // node_freq_band_capability: {};
  serving_cell_pcid: string;
  serving_cell_sinr: number;
  serving_cell_rssi: number;
  neighbors: INeighbor[];
  timestamp: number;
}

export type INeighbor = {
  pcid: string;
  operator_name: string;
  operator_country: string;
  freq_band: string;
  mnc: string;
  mcc: string;
  cell_mode: string;
  bandwidth: string;
  rsrp: number;
  rsrq: number;
  estimated_throughput: number;
};

export type IDeviceOrNodeQoeMetrics = {
  statsDateRange: { start: number; end: number };
} & {
  [nodeId: string]: {
    [channel: number]: {
      [metricName: string]: {
        timestamp: number;
        value: number;
      }[];
    };
  };
};

export interface IDeviceOrNodeLiveModeStream {
  [metricName: string]: {
    timestamp: number;
    value: number;
  }[];
}

export interface ILocationQoe {
  nodes: ({ id: string } & { [key: string]: string })[];
  devices: ({ mac: string } & { [key: string]: string })[];
}

export interface IAppPrioritization {
  enabled?: boolean;
  mode?: 'auto' | 'enable' | 'disable';
  modeRealized?: 'enable' | 'disable';
  template?: 'default' | 'workFromHome' | 'fastGaming' | 'movieTime' | 'custom';
  custom_setting_enabled?: boolean;
  customSettingEnabled?: boolean;
  customSetting?: {
    voice?: 'high' | 'medium' | 'low' | 'immutable';
    videoConferencing?: 'high' | 'medium' | 'low' | 'immutable';
    gaming?: 'high' | 'medium' | 'low' | 'immutable';
    videoStreaming?: 'high' | 'medium' | 'low' | 'immutable';
  };
}
export type autoExpire = {
  expiresAt: string;
  enable?: boolean;
};

export interface IAppPrioritizationV2 {
  enable?: boolean;
  mode?: 'auto' | 'enable' | 'disable';
  category?: { id: string; autoExpire: autoExpire };
  personIds?: { id: string; autoExpire: autoExpire }[];
  deviceIds?: { id: string; autoExpire: autoExpire }[];
  ftuxEngagedAt?: string;
  homeSecurity?: { enable?: boolean };
}

export interface ITrafficClass {
  name: {
    trafficClass: string;
    priority: string;
    dscp: string;
  };
  onlineMinutes: number[];
  rxBytes: number[];
  txBytes: number[];
  totalOnlineMinutes: number;
}

export interface ITrafficLegend {
  id: string;
  alternativeId?: string;
  order: number;
  label: string;
  color: string;
  bytes?: number[];
  timestamps?: string[];
  total?: number[];
}

export interface ITrafficGranularity {
  value: '3h' | '24h' | '7d' | '30d';
  granularity: 'total' | '15 minutes' | '1 hour' | '1 day';
  miliseconds: number;
  translation: string;
  selected: boolean;
  count: number;
  interval?: number;
}

export interface ITrafficConfigurationData {
  name?: string;
  total: number;
  classes: {
    color: string;
    id: string;
    label: string;
    order: number;
    percent?: number;
    bytes: number;
  }[];
}

export interface ITrafficConfiguration {
  top5devices: ITrafficConfigurationData[];
  overview: ITrafficConfigurationData;
}

export interface ITrafficMonitoring {
  mac: string;
  name: string;
  icon: string;
  iconV3?: string;
  connectionState: string;
  expanded: boolean;
  totalUsage: number;
  details: {
    [trafficClass: string]: ITrafficLegend;
  };
  chartData?: ITrafficLegend[];
  chart?: DataSetGroup[];
}

export interface IAppPrioritizationMonitoring {
  devices?: {
    mac: string;
    total: ITrafficClass;
    trafficClasses: ITrafficClass[];
  }[];
  granularity?: 'total' | '15 minutes' | '1 hour' | '1 day';
  startTime?: string;
  endTime?: string;
  macs?: string[];
  dateTimePeriods?: string[];
  trafficClasses?: string[];
  sortOrder?: 'TxBytes' | 'RxBytes';
  limit?: number;
}

export interface IBroadbandEfficiencyAlert {
  summary: {
    twentyFourHours: {
      broadbandDownloadSpeed: {
        average: string;
        standardDeviation: string;
      };
      wifiDownloadSpeed: {
        average: string;
        standardDeviation: string;
      };
    };
    seventyTwoHours: {
      broadbandDownloadSpeed: {
        average: string;
        standardDeviation: string;
      };
      wifiDownloadSpeed: {
        average: string;
        standardDeviation: string;
      };
    };
  };
  nodes: Array<{
    nodeId: string;
    broadbandDownloadSpeed?: {
      average: string;
      standardDeviation: string;
    };
    wifiDownloadSpeed?: {
      average: string;
      standardDeviation: string;
    };
    broadbandEfficiency?: string;
  }>;
}

export interface IMembership {
  status: string;
  ratePlanChargeId: string;
  effectiveAt: any;
  expiresAt: any;
  term: any;
  ratePlans: Array<{ ratePlanId: string; name: string }>;
  ratePlanId: string;
  serviceLevel: any;
  zuoraStatus: string;
}

export interface IInternet {
  customer: {
    id: string;
    accountId: string;
    anonymous: boolean;
    autoProvisioned: boolean;
    name: string;
    locked: boolean;
    partnerId: string;
    acceptLanguage: string;
    preferredLanguage: string;
    source: string;
    createdAt: string;
    _version: string;
    email: string;
    emailVerified: boolean;
    termsAndPrivacy: {
      termsDocumentId: number;
      termsAcceptedAt: string;
      privacyDocumentId: number;
      privacyAcceptedAt: string;
    };
    loginFailure: Array<number>;
    firstKnownLoginTimestamp: string;
  };
  optimization: {
    id: string;
    state: string;
    priority: number;
    triggers: Array<string>;
    deviatedNodes: Array<any>;
    stateChangedAt: number;
    createdAt: string;
    autoOptimization: boolean;
    uuid: string;
  };
  summary: {
    gatewayConnectionState: string;
    nodeConnectedCount: number;
    nodeClaimedCount: number;
    connectedDeviceCount: number;
    _version: string;
    gatewayConnectionStateChangeAt: string;
    wifiNetworkProvisioned: boolean;
    onboardingStatus: string;
    monitorMode: boolean;
    onboardedAt: string;
    poorHealthNodeCount: number;
    poorHealthDeviceCount: number;
    hasResidentialGateway: boolean;
    networkModeProvisioned: string;
    networkModeRealized: string;
    upstreamDnsServers: {
      primaryDns: string;
      secondaryDns: string;
      enabled: boolean;
    };
    geoIp: {
      _version: string;
      updatedAt: string;
      city: string;
      wifiIsoCode: string;
      country: string;
      latitude: number;
      longitude: number;
      timezone: string;
      ISP: string;
      zip: string;
      ip: string;
    };
  };
}

export interface IOptimizationSummary {
  succeeded: number;
  failed: number;
  total: number;
}

export interface IAlarmReports {
  deviceAlarm: Array<{
    timestamp: string;
    value: boolean;
  }>;
  podAlarm: Array<{
    timestamp: string;
    value: boolean;
  }>;
  statsDateRange: {
    start: string;
    end: string;
  };
}

export interface IOptimization {
  locationId: string;
  triggers: 'Manual' | 'TopologyDeviated' | 'Scheduled'[];
  state: 'initiated' | 'inProgress' | 'failed' | 'fail' | 'optimized';
  stateChangedAt: number;
  id: string;
  uuid: string;
}

export interface IPubnubSubscribe {
  authKey?: string;
  subscribeKey: string;
  token?: string;
}

export interface IGuidedTroubleshootSubscribe {
  authKey?: string;
  readChannels: Array<string>;
  writeChannels: Array<string>;
  subscribeKey: string;
  publishKey: string;
  token?: string;
}

export interface IGuidedTroubleshootMessage {
  message: any;
  selections: any;
  selectedOption?: string;
  responseType?: string;
}

export interface IActiveIssue {
  issueType: string;
  issueStartTime: string;
  cspOutageDetected: boolean;
  cspOutageStartTime: string;
}

export interface ITrafficClassStatsTotal {
  granularity: 'total';
  startTime: string;
  devices: Array<{
    mac: string;
    trafficClasses: Array<{
      name: string;
      apps: Array<any>;
      stats: {
        sampleCount: Array<number>;
        good: Array<number>;
        score: Array<number>;
        bad: Array<number>;
        unstable: Array<number>;
        unrecognized: Array<number>;
        inAvg: Array<number>;
        outAvg: Array<number>;
        inNum: Array<number>;
        outNum: Array<number>;
      };
    }>;
  }>;
}

export interface ITrafficClassStatsTimeBased {
  granularity: '1 minute' | '15 minutes' | '1 hour' | '1 day';
  startTime: string;
  devices: Array<{
    mac: string;
    trafficClasses: Array<{
      name: string;
      apps: Array<any>;
      stats: {
        bad: Array<number>;
        good: Array<number>;
        outMin: Array<number>;
        outMax: Array<number>;
        inAvg: Array<number>;
        latencyMin: Array<number>;
        outAvg: Array<number>;
        inNum: Array<number>;
        outNum: Array<number>;
        latencyMax: Array<number>;
        inMin: Array<number>;
        latencyAvg: Array<number>;
        inMax: Array<number>;
        sampleCount: Array<number>;
        unrecognized: Array<number>;
        unstable: Array<number>;
        score: Array<number>;
      };
    }>;
  }>;
}

export interface IReboot {
  nodeid: string;
  createdAt: string;
  reason: string;
  firmwareversion: string;
  model?: string;
  reboottype: string;
  backTrace?: string;
  processId?: string;
  processName?: string;
}
export interface INetworkAccessNetworks {
  networkId: 'default' | 'employee';
  purgatory: boolean;
  captivePortal?: boolean;
  heaven: Array<string>; // array of mac address
}

export interface IClientSteeringTriggers {
  locationId: string;
  mac: string;
  deviceTypeId: string;
  reason: string;
  timestamp: number;
  createdAt: string;
  steerTo: Array<{
    freqBands: Array<string>;
    nodeId: string;
  }>;
  steerFrom: {
    freqBand: string;
    nodeId: string;
  };
  trigger: {
    configSource: string;
    kickReason: string;
    sourceNodeId: string;
    sourceFreqBand: string;
    kickedAt: string;
    currentSNR: string;
    includes: Array<{
      [key: string]: string;
    }>;
    currentRate: string;
    expectedSNR?: string;
    expectedRate?: string;
  };
}

export type IDeviceAlarm = {
  statsDateRange: Array<{
    start: Array<string>;
    end: Array<string>;
    id: string;
  }>;
  id: number;
} & { [key: string]: { timestamp: string; value: number }[] };

export interface IDeviceBandwidth {
  transmitted: Array<{
    value?: number;
    timestamp: string;
  }>;
  received: Array<{
    value?: number;
    timestamp: string;
  }>;
  statsDateRange: {
    start: string;
    end: string;
  };
}

export interface ISchedule {
  id: string;
  name: string;
  type: string;
  schedules: {
    startTime: string;
    endTime?: string;
    daysOfWeek: Array<number>;
  }[];
  createdAt: string;
  updatedAt?: string;
}

export interface IDeviceBandSteeringHistory {
  data24h: {
    points: Array<{
      timestamp: string;
      bs_failed: number;
      bs_troubled: number;
      bs_succeeded: number;
      bs_attempted: number;
    }>;
    statsDateRange: {
      start: string;
      end: string;
    };
  };
  data7d: {
    points: Array<{
      timestamp: string;
      bs_failed: number;
      bs_troubled: number;
      bs_succeeded: number;
      bs_attempted: number;
    }>;
    statsDateRange: {
      start: string;
      end: string;
    };
  };
}

export interface IDeviceClientSteeringHistory {
  data24h: {
    points: Array<{
      timestamp: string;
      cs_failed: number;
      cs_troubled: number;
      cs_succeeded: number;
      cs_attempted: number;
    }>;
    statsDateRange: {
      start: string;
      end: string;
    };
  };
  data7d: {
    points: Array<{
      timestamp: string;
      cs_failed: number;
      cs_troubled: number;
      cs_succeeded: number;
      cs_attempted: number;
    }>;
    statsDateRange: {
      start: string;
      end: string;
    };
  };
}

export interface IDeviceTypeDetail {
  mac: string;
  locationId: string;
  locationIds: Array<string>;
  kind: {
    id: string;
    category: string;
    brand: string;
    name: string;
    icon: string;
    iconV2: string;
    osName: string;
    osVersion: string;
    confidence: number;
    source: string;
    sources: Array<string>;
  };
  features: {
    hostName: string;
    dhcpFingerprint: string;
    vendorClassId: string;
    userAgents: Array<string>;
    mdns: Array<{
      name: string;
      txt: Array<any>;
    }>;
  };
  mappings: {
    version: string;
  };
  sniffingReq: {
    dns: boolean;
    http: boolean;
    upnp: boolean;
    mdns: boolean;
  };
  adtProperties: {
    changeToIncludeMulticastAddr: Array<string>;
    changeToExcludeMulticastAddr: Array<string>;
  };
}

export interface ISearchNode {
  serialNumber: string;
  id: string;
  _version: string;
  subscriptionRequired: boolean;
  residentialGateway: boolean;
  firmwareVersion: string;
  model: string;
  backhaulDhcpPoolIdx: number;
  radioMac24: string;
  ethernetMac: string;
  bluetoothMac: string;
  nickname: string;
  boxSerialNumber: string;
  packId: string;
  timestamp: string;
  updatedAt: string;
  deployment: string;
  shardNumber: string;
  ethernetLan: {
    default: {
      mode: string;
    };
  };
  customerId: string;
  locationId: string;
  vendor: {
    name: string;
    partNumber: string;
    manufacturer: string;
    factory: string;
    mfgDate: string;
  };
  certificates: {
    clientAfter: string;
    clientBefore: string;
    rootAfter: string;
    rootBefore: string;
  };
  kvConfigs: Array<any>;
  subscriptionRequiredTerm: any;
  claimKeyRequired: boolean;
  unclaimable: boolean;
  ethernet1Mac: string;
  radioMac50L: string;
  radioMac50U: string;
  createdAt: string;
  purchaseOrderNumber: string;
  partNumber: string;
  shipDate: string;
}

export interface IFlexEmployee {
  items: Array<{
    employeeId: string;
    locationId?: string;
  }>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface ISystemCloudMatrix {
  anomalydetector: string;
  apptime: string;
  'awlan-optimizer': string;
  'awlan-server': string;
  'bulldog-etl-pipeline': string;
  'captive-portal-service': string;
  cloud_version: string;
  cognitive: string;
  'cohort-config-migration': string;
  connector: string;
  controller: string;
  councilman: string;
  customer: string;
  devicetype: string;
  gatekeeper: string;
  'haystack-alerts': string;
  homeaway: string;
  'lte-service': string;
  'mdu-optimizer-mip': string;
  'mdu-optimizer': string;
  'network-access-server': string;
  notifications: string;
  orchestrator: string;
  overlord: string;
  'ovsdb-proxy': string;
  'plume-noc': string;
  'plumecare-rollup-aggregates': string;
  provisioning: string;
  realtimeinsights: string;
  reports: string;
  'spark-data-pipeline-batch': string;
  streamcoder: string;
  'streamline-lte': string;
  'streamline-main': string;
  'streamline-mqtt': string;
  'streamline-s3-connector': string;
  streamline: string;
  subscriptions: string;
  'captive-portal': string;
  'flex-api': string;
  'nom-link': string;
  'streamline-guard-router': string;
  uprise: string;
  'workpass-integration': string;
  'upgrade-service': string;
}

export interface IGlobalInventoryNode {
  id: string;
  model: string;
  packId: string;
  partnerId: string;
  residentialGateway: boolean;
  claimKeyRequired: boolean;
  subscriptionRequired: boolean;
  ethernet1Mac: string;
  ethernetMac: string;
  radioMac60: string;
  radioMac50L: string;
  radioMac50U: string;
  radioMac24: string;
  radioMac50: string;
}

export interface IIGlobalInventoryNodeResponse extends IGlobalInventoryNode {
  serialNumber: string;
  unclaimable: boolean;
  kvConfigs: [
    {
      id: string;
      module: string;
      key: string;
      value: string;
    }
  ];
  synchronizedKey: string;
  createdAt: string;
}

export interface IEntitledAccess {
  accessId: string;
  ownerId: string;
  name: string;
  email: string;
  accessType: string;
  status: string;
  createdAt: string;
  location: {
    id: string;
    name: string;
  };
}

export interface IUprisePropertyLocationList {
  items: Array<{
    locationId: string;
    customerId: string;
    propertyNetwork: {
      id: string;
      name: string;
    };
    property: {
      id: string;
      name: string;
    };
    partner: {
      id: string;
      name: string;
    };
  }>;
  meta: {
    count: number;
    total: number;
    itemsPerPage: number;
    totalPages: number;
    page: number;
  };
}

export interface ICustomerGroup {
  _version: string;
  name: string;
  description: string;
  createdAt: string;
  id: string;
}

export interface IWifiNetwork {
  ssid: string;
  masterKeyIndex: number;
  wpaMode: string;
  uapsd: boolean;
  enabled: boolean;
  disableDefaultServiceNetwork: boolean;
  groupRekey: string;
  fastTransition: string;
  minWifiMode24: string;
  privateMode: boolean;
  privateModeUpdatedAt: string;
  devicesVisibleToGuests: Array<any>;
  createdAt: string;
  updatedAt: string;
  _version: string;
  wpaModeDeferredExpiresAt: any;
  id: string;
  locationId: string;
  keys: Array<{
    id: number;
    accessZone: string;
    accessZoneId: number;
    format: string;
    enable: boolean;
    createdAt: string;
    updatedAt: string;
    _version: string;
  }>;
  persons: Array<any>;
  devices: Array<any>;
  accessZones: Array<{
    id: number;
    type: string;
    description: string;
    createdAt: string;
    _version: string;
  }>;
  encrypted: boolean;
  encryptedAt: string;
}

export interface ILocationLog {
  _version: string;
  comment: string;
  podFilenameMap: {
    [key: string]: string;
  };
  createdAt: string;
  id: string;
  link?: string;
  copyLink?: string;
  locationId: string;
  topology: Array<{
    id: string;
    model: string;
    connectionState: string;
    isGateway: boolean;
    firmwareVersion: string;
    platformVersion: string;
    ip: string;
    ipv6: Array<string>;
    networkMode: string;
    wifiConfig: Array<{
      freqBand: string;
      channel: number;
      channelWidth: number;
      parentId: string;
      devices: Array<{
        mac: string;
        ip: string;
        hostName: string;
        vapType: string;
        ipv6: Array<string>;
        networkId?: string;
      }>;
      vaps: Array<string>;
      region?: string;
      parentVapType?: string;
    }>;
    ethernetDevices: Array<any>;
    mocaDevices: Array<any>;
    misconnectedToUplinkHardware: boolean;
    bootTime: number;
  }>;
  nodes: Array<{
    __cachedRelations: {};
    __data: {
      id: string;
      _version: string;
      subscriptionRequired: boolean;
      residentialGateway: boolean;
      firmwareVersion: string;
      model: string;
      backhaulDhcpPoolIdx: number;
      radioMac24: string;
      ethernetMac: string;
      bluetoothMac: string;
      boxSerialNumber: string;
      packId: string;
      timestamp: string;
      updatedAt: string;
      deployment: string;
      shardNumber: string;
      ethernetLan: {
        default: {
          mode: string;
        };
      };
      customerId: string;
      locationId: string;
      vendor: {
        name: string;
        partNumber: string;
        manufacturer: string;
        factory: string;
        mfgDate: string;
      };
      certificates: {
        clientAfter: string;
        clientBefore: string;
        rootAfter: string;
        rootBefore: string;
      };
      kvConfigs: Array<{
        module: string;
        key: string;
        value: string;
        persist: boolean;
        createdAt: string;
        updatedAt: string;
        id: string;
      }>;
      subscriptionRequiredTerm: any;
      claimKeyRequired: boolean;
      unclaimable: boolean;
      ethernet1Mac?: string;
      radioMac50L?: string;
      radioMac50U?: string;
      createdAt: string;
      purchaseOrderNumber: string;
      partNumber: string;
      shipDate: string;
      serialNumber: string;
      radioMac50?: string;
      synchronizedKey?: string;
      nickname?: string;
    };
    __dataSource: any;
    __strict: boolean;
    __persisted: boolean;
    subscriptionRequired: boolean;
    residentialGateway: boolean;
    firmwareVersion: string;
    model: string;
    boxSerialNumber: string;
    packId: string;
    deployment: string;
    shardNumber: string;
    ethernetLan: {
      default: {
        mode: string;
      };
    };
    customerId: string;
    vendor: {
      name: string;
      partNumber: string;
      manufacturer: string;
      factory: string;
      mfgDate: string;
    };
    certificates: {
      clientAfter: string;
      clientBefore: string;
      rootAfter: string;
      rootBefore: string;
    };
    kvConfigs: Array<{
      module: string;
      key: string;
      value: string;
      persist: boolean;
      createdAt: string;
      updatedAt: string;
      id: string;
    }>;
    subscriptionRequiredTerm: any;
    claimKeyRequired: boolean;
    unclaimable: boolean;
    ethernet1Mac?: string;
    radioMac50L?: string;
    radioMac50U?: string;
    createdAt: string;
    purchaseOrderNumber: string;
    partNumber: string;
    shipDate: string;
    serialNumber: string;
    _version: string;
    backhaulDhcpPoolIdx: number;
    radioMac24: string;
    radioMac50?: string;
    radioMac60: any;
    ethernetMac: string;
    bluetoothMac: string;
    blacklists: any;
    connectionState: any;
    countryCode: any;
    function: any;
    macs: any;
    status: any;
    timestamp: string;
    updatedAt: string;
    locationId: string;
    synchronizedKey?: string;
    nickname?: string;
  }>;
  expiresAt?: string;
}

export interface IMobilizeLog {
  Key: string;
  LastModified: string;
  Size: number;
}

export interface ISecondaryNetworks {
  guest: ISecondaryNetworkCaptivePortal;
  employee: ISecondaryNetworkFronthaul;
  secondary?: ISecondaryNetworkFronthaul;
}

export interface ISecondaryNetworkFronthaul {
  networkId: string;
  vapType: string;
  enable: boolean;
  ssid: string;
  accessZone: string;
  wpaMode: string;
}

export interface ISecondaryNetworkCaptivePortal {
  networkId: string;
  vapType: string;
  enable: boolean;
  ssid: string;
  bandwidthLimit?: {
    enabled: boolean;
    type: string;
    upload: number;
    download: number;
  };
  wpaMode: string;
}

export interface ISecurityPolicy {
  secureAndProtect: boolean;
  iotProtect: boolean;
  iotProtectUpdatedAt: string;
  content: Array<any>;
  adBlocking?: boolean;
  workAppropriate?: boolean;
  adultAndSensitive?: boolean;
  teenagers?: boolean;
  kids?: boolean;
  websites: {
    whitelist: Array<string>;
    whitelisted: Array<{
      value: string;
      type: string;
      direction: string;
      createdAt: string;
    }>;
    blacklist: Array<any>;
    blacklisted: Array<any>;
  };
  createdAt: string;
  appliesToAllDevices: {
    secureAndProtect: boolean;
    iotProtect: boolean;
    adultAndSensitive: boolean;
    kids: boolean;
    teenagers: boolean;
    adBlocking: boolean;
    workAppropriate: boolean;
    websitesWhitelist: boolean;
    websitesBlacklist: boolean;
  };
  anomalyBlacklist?: {
    createdAt: string;
    deviceType: string;
    enableQuarantine: boolean;
    fqdn: string;
    ipv4: string[];
    ipv6: string[];
    mac: string;
  }[];
  anomalyWhitelist?: {
    createdAt: string;
    deviceType: string;
    enableQuarantine: boolean;
    fqdn: string;
    ipv4: string[];
    ipv6: string[];
    mac: string;
  }[];
}

export interface ISecurityPolicyEvent {
  eventType:
    | 'IoTProtect.blacklistedAnomaly'
    | 'IoTProtect.rolledbackAnomaly'
    | 'IoTProtect.whitelistedAnomaly'
    | 'adBlocking'
    | 'kids'
    | 'teenagers'
    | 'workAppropriate'
    | 'secureAndProtect'
    | 'adultAndSensitive'
    | 'SecureAndProtect.outboundIpBlocked'
    | 'SecureAndProtect.inboundIpBlocked';
  createdAt: string;
  eventData: {
    fqdn?: string;
    mac: string;
    protectionType: string;
    reason?: string;
    url: any;
    sourceCategoryId?: number;
    source?: string;
    queryType?: string;
    eventCount?: number;
  };
}

export interface IDhcp {
  endIp: string;
  startIp: string;
  subnet: string;
  subnetMask: string;
}

export interface IDhcpReservation {
  mac: string;
  ip: string;
  portForwards: Array<{
    externalPort: number;
    internalPort: number;
    protocol: string;
    name: string;
    natLoopback?: string;
  }>;
  name: string;
}

export interface IDHCPLeaseRecord {
  mac: string;
  ip: string;
  hostname: string;
  fingerprint: {
    paramList: string;
    vendorClassId: string;
  };
  leaseTime: Number;
}

export interface IDHCPV6Record {
  mac: string;
  ip: string;
  hostname: string;
  fingerprint: {
    paramList: string;
    vendorClassId: string;
  };
  leaseTime: Number;
}

export interface IDHCPLeases {
  dhcpLeases: IDHCPLeaseRecord[];
  dhcpV6Leases: IDHCPV6Record[];
}

export interface IWanConfiguration {
  staticIPv4: {
    gateway?: string;
    primaryDns?: string;
    enabled: boolean;
    secondaryDns?: string;
    ip?: string;
    subnet?: string;
  };
  uplink: {
    vlan?: number;
    enabled: boolean;
    bridgeName?: string;
    hardwarePort?: string;
    qos?: number;
  };
  publishedWithBLE?: boolean;
  pppoe: {
    enabled: boolean;
    username?: string;
    password?: string;
  };
}

export interface IMacStitching {
  mac: string;
  timestamp?: string;
  details?: string;
}

export interface IDeviceTOS {
  mac: string;
  idle?: boolean;
  kickStatus?: {
    directedKickStatus: any;
    speculativeKickStatus: any;
  };
  idleChangedAt?: string;
  poorThroughputCount?: number;
  lastSteeringResult: {
    timestamp: string;
    result: string;
  };
  lastKickedAt?: string;
  backoffsDirty?: boolean;
}

export interface ISsoAuditTrail {
  id: string;
  accountId: string;
  partnerId: string;
  createdAt: string;
  expireAt: string;
  event: string;
  xRequestId: string;
}

export interface IAppFacade {
  dashboard: {
    autoRun: {
      _version: string;
      enable: boolean;
      enableAllNodes: boolean;
      createdAt: string;
      updatedAt: string;
    };
    isp: string;
    mostRecent: {
      download: number;
      upload: number;
      timestamp: number;
      speedLevel: string;
    };
    hasBeenOptimized: boolean;
    optimization: {
      id: string;
      state: string;
      priority: number;
      triggers: Array<string>;
      deviatedNodes: Array<any>;
      stateChangedAt: number;
      createdAt: string;
      autoOptimization: boolean;
      uuid: string;
    };
  };
  day: {
    chart: Array<{
      timestamp: number;
      manual?: boolean;
      download?: number;
      upload?: number;
    }>;
    hasData: boolean;
    average: {
      download: number;
      upload: number;
      resultCount: number;
    };
    devices: {
      totalConsumption: {
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
      };
      mostActive: Array<{
        mac: string;
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
        name: string;
        icon: string;
        iconV2?: string;
      }>;
    };
  };
  week: {
    chart: Array<{
      timestamp: number;
      download?: number;
      upload?: number;
    }>;
    hasData: boolean;
    devices: {
      totalConsumption: {
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
      };
      mostActive: Array<{
        mac: string;
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
        name: string;
        icon: string;
        iconV2?: string;
      }>;
    };
  };
  month: {
    chart: Array<{
      timestamp: number;
      download?: number;
      upload?: number;
    }>;
    hasData: boolean;
    devices: {
      totalConsumption: {
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
      };
      mostActive: Array<{
        mac: string;
        download: number;
        upload: number;
        downloadUnits: string;
        uploadUnits: string;
        name: string;
        icon: string;
        iconV2?: string;
      }>;
    };
  };
}

export interface IWifiMotion {
  auto: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IAppTime {
  enable?: boolean;
  enableRealized?: boolean;
  appliesToAllDevices?: boolean;
}

export interface IWanStatsMonthly {
  days: Array<{
    timestamp: string;
    rxMbytes?: number;
    txMbytes?: number;
    rxMaxMbps?: number;
    txMaxMbps?: number;
  }>;
}

export interface IWanStatsDaily {
  fifteenMins: Array<{
    timestamp: string;
    rxMbytes?: number;
    txMbytes?: number;
    rxMaxMbps?: number;
    txMaxMbps?: number;
  }>;
}

export interface IWanStatsWeekly {
  hours: Array<{
    timestamp: string;
    rxMbytes: number;
    txMbytes: number;
    txMaxMbps: number;
    rxMaxMbps: number;
  }>;
}

export interface IPerson {
  id: string;
  nickname: string;
  imageId: string;
  primaryDevice?: string;
  homeAwayNotification: boolean;
  appTime: boolean;
  permission: {
    accessType: string;
  };
  createdAt: string;
  locationId: string;
  freeze: {
    timeTemplates: Array<any>;
    suspended: {
      id: string;
      name: string;
      enable: boolean;
    };
    autoExpire: {
      id: string;
      enable: boolean;
      expiresAt: any;
    };
    schedules: Array<any>;
    forever: {
      id: string;
      name: string;
      enable: boolean;
    };
    frozen: boolean;
  };
  connectionState?: string;
  connectionStateChangeAt?: string;
}

export interface IHomeSecurity {
  liveMotionEnabled: boolean;
  motionEventsEnabled: boolean;
  petMode: string;
  cooldown: number;
  sensitivity: string;
  homeAwayActive: boolean;
}

export interface IHomeSecurityDevicesSounding {
  soundingStates: Array<{
    mac: string;
    auto: boolean;
    enable: boolean;
  }>;
}

export interface IAppFacadeOptimization {
  customer: {
    id: string;
    accountId: string;
    anonymous: boolean;
    autoProvisioned: boolean;
    name: string;
    locked: boolean;
    partnerId: string;
    acceptLanguage: string;
    preferredLanguage: string;
    source: string;
    createdAt: string;
    provisioningSsoAuditTrail: boolean;
    _version: string;
    email: string;
    emailVerified: boolean;
    termsAndPrivacy: {
      termsDocumentId: number;
      termsAcceptedAt: string;
      privacyDocumentId: number;
      privacyAcceptedAt: string;
    };
    loginFailure: Array<number>;
    firstKnownLoginTimestamp: string;
  };
  optimization: {
    id: string;
    state: string;
    priority: number;
    triggers: Array<string>;
    deviatedNodes: Array<any>;
    stateChangedAt: number;
    createdAt: string;
    autoOptimization: boolean;
    uuid: string;
  };
}

export interface IFirmware {
  minReqdVerForOnboarding: string;
  mininumRequiredVersionsForOnboarding: {
    [key: string]: string;
  };
  upgradeRecommended: boolean;
  upgradeRecommendedPostOnboarding: boolean;
  updatedAt: string;
  summaryState?: string;
  nodes?: { nodeId: string }[];
}

export interface INetworkConfiguration {
  networkModeProvisioned: string;
  networkModeRealized: string;
  dnsServers: {
    primaryDns: string;
    secondaryDns: string;
    enabled: boolean;
  };
  upnp: {
    mode: string;
    enabled: boolean;
    modeRealized: boolean;
  };
  multicast: {
    igmpSnooping: {
      mode: string;
    };
    igmpProxy: {
      mode: string;
    };
    mldProxy: {
      mode: string;
    };
    multicastToUnicast: {
      mode: string;
    };
  };
  ethernetLan: {
    mode: string;
  };
  persistConfigurationOnGateway: {
    mode: string;
    modeRealized: string;
  };
  subnet: string;
  subnetProvisioned: string;
  subnetRealized: string;
}

export interface IAddAccessZone {
  id: number;
  type: string;
  description: string;
  accessibleDevices: Array<string>;
  createdAt: string;
  _version: string;
}

export interface IAddAccessZoneKey {
  id: number;
  encryptionKey: string;
  accessZone: string;
  accessZoneId: number;
  format: string;
  enable: boolean;
  content: any;
  createdAt: string;
  updatedAt?: string;
  _version: string;
  accessibleDevices?: Array<string>;
  expiresAt?: string;
}

export interface INetworkAccessDeviceGroup {
  groupId: string;
  networkId: string;
  name: string;
  devices: Array<string>;
  groupShares: Array<string>;
  standalone: boolean;
}
export interface IVersionMatrices {
  versionMatrix: string;
  versionMatrixDescription?: string;
  createdAt: string;
  minReqdVerForOnboardingConfigIds: Array<string>;
  useForAutoUpgradeDuringOnboardingConfigIds: Array<string>;
  models: Array<{
    firmwareVersion: string;
    model: string;
    residentialGateway?: boolean;
    downloadUrl: string;
    encryptedDownloadUrl?: string;
    residentalGateway?: boolean;
    modules?: Array<{
      name: string;
      version: string;
      filename: string;
    }>;
    platformVersion?: string;
  }>;
  firmwareVersion?: string;
}

export interface ISniffing {
  dns: {
    mode: 'auto' | 'enable' | 'disable';
  };
  http: {
    mode: 'auto' | 'enable' | 'disable';
  };
  upnp: {
    mode: 'auto' | 'enable' | 'disable';
  };
  mdns: {
    mode: 'auto' | 'enable' | 'disable';
  };
}

export interface IManager {
  accessId: string;
  managerId: string;
  name: string;
  email: string;
  accessType: string;
  status: string;
  createdAt: string;
  locationId: string;
}

export interface IManagerAdded {
  ownerId: string;
  locationId: string;
  managerId: string;
  accessType: string;
  status: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
}

export interface IFirmwareUpgrade {
  locations: Array<{
    id: string;
    groups: Array<string>;
    pendingVersionMatrixId: string;
    nodeModels: Array<string | undefined>;
  }>;
  batchFirmwareUpgrade: {
    locationCriteria: {
      locations: Array<any>;
      locationsModifier: string;
      numberOfLocations: any;
      numberOfLocationsModifier: string;
      groupsModifier: string;
      pendingVersionMatricesModifier: string;
      activeVersionMatricesModifier: string;
      nodeModelsModifier: string;
      targetVersionMatrix: string;
    };
    upgradeParameters: {
      targetVersionMatrix: string;
      schedule: {
        startTime: string;
        endTime: string;
        startDate?: string;
      };
      bypassBackpressure: boolean;
      preventDowngrade: boolean;
      batchFirmwareUpgradeIdToBeCleared: string;
    };
    batchSize: number;
    locationIds: Array<string>;
    timestamp: string;
    userIdOfRequestor: string;
    userIdOfApprover: string;
    id: string;
  };
}

export interface IFirmwareUpgradeCriteria {
  locationCriteria: {
    shardIds: string[];
    nodeModelsModifier: string;
    locationsModifier: string;
    groupsModifier: string;
    activeVersionMatricesModifier: string;
    numberOfLocations: number;
    locations: any;
    nodeModels: string[];
    activeVersionMatrices: any;
    groups: string[];
  };
  upgradeParameters: {
    targetVersionMatrix: any;
    preventDowngrade: boolean;
    schedule: {
      startTime: any;
      endTime: any;
      startDate?: string;
    };
  };
}

export interface IFirmwareUpgradeReportsCriteria {
  locationCriteria: {
    numberOfLocations?: number;
    countries?: string[];
    cities?: string[];
    includeGroupIds?: string[];
    excludeGroupIds?: string[];
    partnerIds?: string[];
    nodeModels?: string[];
    includeActiveVersionMatrixIds?: string[];
    excludeActiveVersionMatrixIds?: string[];
    includePendingVersionMatrixIds?: string[];
    excludePendingVersionMatrixIds?: string[];
    includeLocationIds?: string[];
    excludeLocationIds?: string[];
    locations?: string[];
  };
  upgradeParameters?: {
    targetVersionMatrix: any;
    preventDowngrade: boolean;
    schedule: {
      startTime: any;
      endTime: any;
      startDate?: string;
    };
  };
}

export interface IFirmwareUpgradeReport {
  id: string;
  locationIds: string[];
}

export interface IFirmwareReports {
  batchFirmwareUpgrade: Array<{
    locationCriteria: {
      locations: Array<string>;
      locationsModifier: string;
      numberOfLocationsModifier: string;
      groupsModifier: string;
      pendingVersionMatricesModifier: string;
      activeVersionMatricesModifier: string;
      nodeModelsModifier: string;
      targetVersionMatrix?: string;
    };
    upgradeParameters: {
      targetVersionMatrix: string;
      bypassBackpressure: boolean;
      preventDowngrade: boolean;
      schedule?: {
        startTime: string;
        endTime: string;
        startDate?: string;
      };
    };
    batchSize: number;
    userIdOfRequestor: string;
    userIdOfApprover?: string;
    locationIds: Array<string>;
    timestamp: string;
    id: string;
  }>;
}

export interface IFirmwareBatchReport {
  scheduled: {
    locationIds: Array<string>;
    count: number;
  };
  updated: {
    locationIds: Array<string>;
    count: number;
  };
  cancelled: {
    locationIds: Array<string>;
    count: number;
  };
  skipped: {
    locationIds: Array<string>;
    count: number;
  };
  failed: {
    locationIds: Array<string>;
    count: number;
  };
}

export interface ITopologyChange {
  locationId: string;
  topology: Array<{
    ip?: string;
    isGateway?: boolean;
    connectionState: string;
    wifiConfig: Array<{
      freqBand: string;
      channelWidth: string;
      channel: string;
      vaps: Array<string>;
      region: string;
      parentvapType?: string;
      parentId: string;
      devices?: Array<{
        hostName: string;
        ipv6: Array<string>;
        ip: string;
        vapType: string;
        networkid: {};
        mac: string;
      }>;
    }>;
    networkMode?: string;
    ethernetdevices?: Array<{
      hostName: string;
      ipv6: Array<string>;
      ip: string;
      mac: string;
    }>;
    misconnectedtouplinkhardware?: boolean;
    firmwareVersion: string;
    ipv6: Array<string>;
    boottime?: string;
    model: string;
    id: string;
    platformVersion?: string;
    wanIp?: string;
  }>;
  timestamp: number;
}

export interface IDns {
  enabled: boolean;
  primaryDns: string;
  secondaryDns: string;
}

export interface IIpTv {
  enable: boolean;
  serviceNetworkMode?: string;
  name: string;
}

export interface ITaskStatus {
  id: string;
  name: string;
  status: 'Succeeded' | 'Skipped' | 'TimedOut' | 'Canceled';
  finishedAt: string;
  failedAction?: string;
}

export interface ITaskStatuses {
  [key: string]: ITaskStatus[];
}

export interface INodeTaskStatus {
  id: string;
  name?: string;
  icon?: string;
  model?: string;
  taskCount: number;
  taskStatuses: ITaskStatus[];
}

export interface IVapType {
  nodeId: string;
  vapsState: IVapState[];
}

export interface IVapState {
  vapType: string;
  realizedOnRadios: string[];
  wifiNetwork: IVapStateWifiNetwork;
}

export interface IVapStateWifiNetwork {
  ssid: string;
  encryption: string;
  encryptionMode: string;
  wpaMode: string;
  ssidBroadcast: boolean;
  enabled: boolean;
  minHwMode?: string;
  bssid: string;
  channelWidth: number;
  channel: number;
}

export interface IMapConfig {
  mode: 'AUTO' | 'ENABLE' | 'DISABLE';
  mapType?: 'MAP_TYPE_E' | 'MAP_TYPE_T';
  mapRulesDhcp?: boolean | { value: boolean };
  mapRulesUrl?: string | { value: string };
  bmrIpv6Prefix?: string | { value: string };
  bmrIpv4Prefix?: string | { value: string };
  bmrEaLength?: number | { value: number };
  bmrPsidOffset?: number | { value: number };
  dmr?: string | { value: string };
  mapLegacyDraft?: boolean | { value: boolean };
  otherConfig?: {
    key: string;
    value: number | string | boolean;
  }[];
}

export interface IMapConfigState {
  enable: boolean;
  mapType?: 'MAP_TYPE_E' | 'MAP_TYPE_T';
  mapRulesDhcp?: boolean | { value: boolean };
  mapRulesUrl?: string | { value: string };
  bmrIpv6Prefix?: string | { value: string };
  bmrIpv4Prefix?: string | { value: string };
  bmrEaLength?: number | { value: number };
  bmrPsidOffset?: number | { value: number };
  dmr?: string | { value: string };
  mapLegacyDraft?: boolean | { value: boolean };
  ifName?: string | { value: string };
  psid?: number | { value: number };
  psidLen?: number | { value: number };
  mapIpv4Addr?: string | { value: string };
  mapIpv6Addr?: string | { value: string };
  portSets?: string | { value: string };
}

export interface ICatalog {
  [key: string]: IModelRef;
}

export interface IModelRef {
  name: string;
  id?: string;
  icon?: string;
  icons?: {
    dark: string;
    light: string;
  };
  manufacturer?: string;
  wifiCapabilities?: {
    radio24?: string;
    radio50?: string;
    radio50L?: string;
    radio50U?: string;
    radio60?: string;
  };
}

export interface IFocus {
  id?: string;
  name?: string;
  macs?: [string];
  persons?: [string];
  groups?: [string];
  enabled?: boolean;
  appCategoriesBlocklist?: [string];
  appsBlocklist?: [string];
  websitesBlocklist?: [
    {
      type?: string;
      value?: string;
      direction?: string;
      geoLocation?: any;
      id?: string;
    }
  ];
  createdAt?: string;
  timer?: {
    startAt?: string;
    endAt?: string;
  };
  schedule?: {
    schedules?: [
      {
        startTime?: string;
        endTime?: string;
        daysOfWeek?: [number];
      }
    ];
  };
}

export type HardwareHealthRadioTempResponse = {
  freq_band: string;
  value: string;
};
export type PSMemUtil = {
  cmd: string;
  util: string;
};
export type PSCpuUtil = {
  cmd: string;
  util: string;
};
export type HardwareHealthItemResponse = {
  endTs: string;
  cpuUtilPct: string;
  memUsedPct: string;
  fanRpm: string;
  radioTemp: HardwareHealthRadioTempResponse[];
  freqBand24: string;
  freqBand50: string;
  freqBand50low: string;
  freqBand50high: string;
  freqBand60: string;
  uptime: number;
  psMemUtil: PSMemUtil[];
  psCpuUtil: PSCpuUtil[];
};

export type HardwareHealthNodeResponse = {
  node_id: string;
  healthTimeseries: HardwareHealthItemResponse[];
};
