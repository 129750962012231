import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { children } from 'src/assets/parsedIcons.json';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { DeviceRefService } from './deviceref.service';
import { LoggingService } from './logging.service';

@Injectable()
export class IconService {
  icons: any = {};
  iconsV2: any = [];
  iconsV2Root: string = 'iconsV2';
  env: any = {};
  path: string = '';
  prefix: string = '';
  catalog: string[] = [];

  constructor(private api: ApiService, private deviceRef: DeviceRefService, private logging: LoggingService) {
    children.forEach((icon: any) => {
      this.iconsV2.push(icon.name.split(icon.extension)[0] || icon.name);
    });
    this.path = environment.metasaurus.url;
    this.prefix = environment.metasaurus?.devicePrefix;
    this.catalog = deviceRef.getCatalog();
  }

  get(icon: string): any {
    if (!this.icons[icon]) {
      this.icons[icon] = new BehaviorSubject<any>(null);
      this.api.raw('get', icon, {}, { responseType: 'text' }).subscribe((response: any) => {
        this.icons[icon].next(response);
      });
    }

    return this.icons[icon];
  }

  set(icon: string, content: string): void {
    this.icons[icon] = content;
  }

  getPath(name: string): string {
    return 'assets/' + this.iconsV2Root + '/' + this.identify(name) + '.svg';
  }

  getV3Path(name: string, size: string): string {
    const check: boolean = this.deviceRef.getCatalog().includes(name);
    name = check ? encodeURIComponent(name) : 'unknown';
    return `${this.path}${this.prefix}/${name}/${size}.svg`;
  }

  identify(name: string): string {
    let found = -1;
    let depth = 0;

    if (!name) {
      return 'unknown';
    }

    while (name && found < 0 && depth < 10) {
      depth++; // depth counter in case of wacky name
      found = this.iconsV2.indexOf(name);
      name = name.substring(0, name.lastIndexOf('-'));
    }

    return this.iconsV2[found] || 'unknown';
  }
}
